import logo from './assets/logo.png';
import './App.scss';
import { Route, Switch, Link } from 'react-router-dom';
import Home from './Components/Home';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsOfService from './Components/TermsOfService';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <Link to='/'>
          <img src={logo} className='App-logo' alt='logo' />
        </Link>
      </header>
      <div className='App-body'>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/terms' component={TermsOfService} />
          <Route path='/privacy' component={PrivacyPolicy} />
        </Switch>
      </div>
      <footer className='Footer-container'>
        <p className='Footer-text Cutback-name'> © 2020 Cutback Budgeting LLC.</p>
        <p className='Footer-text'>
          <Link to='/terms'>Terms Of Service</Link>
        </p>
        <p className='Footer-text'>
          <Link to='/privacy'>Privacy Policy</Link>
        </p>
      </footer>
    </div>
  );
}

export default App;
