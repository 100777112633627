import demoApp from '../assets/demo-app.png'

function Home() {
  return (
    <div className='Body-container'>
      <div className='Left-container'>
        <p className='Body-text'>
          We are excited to launch a new financing app that lets you take
          control of your expenses without the intimidation or fear of money.
        </p>
        <p className='Body-text'>More details coming soon.</p>
      </div>
      <div className='Right-container'>
        <img src={demoApp} className='App-image' alt='app' />
      </div>
    </div>
  );
}

export default Home;
